import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "../app/index";
import RequireAuth from "../components/RequireAuth";
import { Locations } from "../interfaces/enums";
import { RoutesPermissions } from "../components/RequireFlag";
import VerifyEmail from "../components/VerifyEmail";
import { CcyConversionProvider } from "../contexts/CcyConversionContext";

import Login from "../pages/login";
import SignUp from "../pages/signUp";
import ErrorBoundary from "../pages/error";
import ResetPassword from "../pages/resetPassword";
import Onboarding from "../pages/onboarding";

const PlaceOrder = lazy(() => import("../pages/placeOrder"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const AdminDashboard = lazy(() => import("../pages/adminDashboard"));
const SendOnboarding = lazy(() => import("../pages/sendOnboarding"));
const Documentation = lazy(() => import("../pages/documentation"));
const Desk = lazy(() => import("../pages/desk"));
const OrderHistory = lazy(() => import("../pages/ordersHistory"));
const OrdersCcyConversion = lazy(() => import("../pages/ordersCcyConversion"));
const Exchanges = lazy(() => import("../pages/exchanges"));
const DestinationBankAccounts = lazy(
  () => import("../pages/destinationBankAccountsCcyConversion/Bitso"),
);
const DestinationBankAccountsRio = lazy(
  () => import("../pages/destinationBankAccountsCcyConversion/Rio"),
);
const OrderCcyConversion = lazy(
  () => import("../pages/orderCCyConversion/Bitso"),
);
const RioGetQuote = lazy(
  () => import("../pages/orderCCyConversion/Rio/GetQuote"),
);
const RioBrokerGetQuote = lazy(
  () => import("../pages/orderCCyConversion/Rio/BrokerGetQuote"),
);
const RioOrderPage = lazy(
  () => import("../pages/orderCCyConversion/Rio/OrderPage"),
);
const CalizaOrderPage = lazy(
  () => import("../pages/orderCCyConversion/Caliza/OrderPage"),
);
const CalizaGetQuote = lazy(
  () => import("../pages/orderCCyConversion/Caliza/GetQuote"),
);

const RidianRouteDetector = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on every route change
    window.scrollTo(0, 0);
  }, [location]);

  return <Routes>{props.children}</Routes>;
};

const SuspenseElement = () => {
  return null;
};

const RidianRoutes = () => (
  <BrowserRouter>
    <RidianRouteDetector>
      {/* Private Routes */}
      <Route
        path={Locations["/"]}
        element={
          <Sentry.ErrorBoundary fallback={new ErrorBoundary() as any}>
            <RequireAuth redirectTo={Locations["/login"]}>
              <App />
            </RequireAuth>
          </Sentry.ErrorBoundary>
        }
      >
        <Route path={Locations["/"]} element={<RoutesPermissions />}>
          <Route path="/ccy-conversion" element={<CcyConversionProvider />}>
            {/* Trading Desk */}
            <Route
              path={Locations["/ccy-conversion/mm"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <Desk />
                </Suspense>
              }
            />

            {/* Currency Conversion */}

            <Route
              path={Locations["/ccy-conversion/order/:id"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <OrderCcyConversion />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/place-order"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <OrderCcyConversion />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/orders"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <OrdersCcyConversion />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/destination-bank-accounts"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <DestinationBankAccounts />
                </Suspense>
              }
            />

            {/* RIO */}
            <Route
              path={Locations["/ccy-conversion/order-rio/:id"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <RioOrderPage />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/place-order-rio"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <RioGetQuote />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/order-caliza/:id"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <CalizaOrderPage />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/place-order-caliza"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <CalizaGetQuote />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/place-order-rio-broker"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <RioBrokerGetQuote />
                </Suspense>
              }
            />

            <Route
              path={Locations["/ccy-conversion/destination-bank-accounts-rio"]}
              element={
                <Suspense fallback={<SuspenseElement />}>
                  <DestinationBankAccountsRio />
                </Suspense>
              }
            />
          </Route>

          {/* OTCs */}

          <Route
            path={Locations["/dashboard"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <Dashboard />
              </Suspense>
            }
          />

          <Route
            path={Locations["/place-order"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <PlaceOrder />
              </Suspense>
            }
          />
          <Route
            path={Locations["/documentation"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <Documentation />
              </Suspense>
            }
          />

          <Route
            path={Locations["/orders"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <OrderHistory />
              </Suspense>
            }
          />

          <Route
            path={Locations["/exchanges"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <Exchanges />
              </Suspense>
            }
          />
          {/* Admin-Specific Routes */}
          <Route
            path={Locations["/admin"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <AdminDashboard />
              </Suspense>
            }
          />

          <Route
            path={Locations["/send-onboarding"]}
            element={
              <Suspense fallback={<SuspenseElement />}>
                <SendOnboarding />
              </Suspense>
            }
          />
        </Route>
      </Route>
      {/* Public Routes */}
      <Route path={Locations["/login"]} element={<Login />} />
      <Route path={Locations["/onboarding"]} element={<Onboarding />} />
      <Route path={Locations["/sign-up"]} element={<SignUp />} />
      <Route path={Locations["/verify-email"]} element={<VerifyEmail />} />
      <Route path={Locations["/reset-password"]} element={<ResetPassword />} />
      <Route
        path={Locations["/access-denied"]}
        element={<h1>Access denied</h1>}
      />
    </RidianRouteDetector>
  </BrowserRouter>
);

export default RidianRoutes;
