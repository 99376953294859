import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FileStructure } from "interfaces";
import { useTranslation } from "react-i18next";

import { IDTypes } from "interfaces/enums";
import { UploadOrShow } from "./UploadOrShow";

export const UploadByType = ({
  doDrop,
  document_ine_anverso,
  document_ine_reverso,
  passport,
  idType,
  handleSendType,
  handleDelete,
  isUploading,
}: {
  doDrop: (files: any, filename: string, category?: string) => void;
  passport?: Array<FileStructure>;
  document_ine_anverso?: Array<FileStructure>;
  document_ine_reverso?: Array<FileStructure>;
  idType: IDTypes;
  handleSendType: (id: IDTypes) => void;
  handleDelete?: (id: string, category: string) => void;
  isUploading: boolean;
}) => {
  const { t } = useTranslation();

  const byDocument = () => {
    switch (idType) {
      case IDTypes.passport:
        return (
          <UploadOrShow
            isUploading={isUploading}
            multiUpload={false}
            category={"document_id"}
            doDrop={doDrop}
            files={passport}
            handleDelete={handleDelete}
            label={t("Pasaporte")}
          />
        );
      case IDTypes.INE:
        return (
          <div className="flex flex-col gap-4">
            {["anverso", "reverso"].map((named) => {
              const idfile =
                named === "anverso"
                  ? document_ine_anverso
                  : document_ine_reverso;

              const label =
                named === "anverso" ? t("INE por delante") : t("INE por atrás");

              return (
                <UploadOrShow
                  isUploading={isUploading}
                  multiUpload={false}
                  category={`document_ine_${named}`}
                  doDrop={doDrop}
                  files={idfile}
                  handleDelete={handleDelete}
                  label={label}
                />
              );
            })}
          </div>
        );
    }
  };
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {t("Tipo de identificación")}
        </InputLabel>

        <Select
          labelId={"tipo_institucion"}
          label={t("Tipo de identificación")}
          onChange={(e) => {
            const _id_type = e.target.value as IDTypes;
            handleSendType(_id_type);
          }}
          value={idType}
        >
          <MenuItem value={IDTypes.INE}>{t("INE")}</MenuItem>
          <MenuItem value={IDTypes.passport}>{t("Pasaporte")}</MenuItem>
        </Select>
      </FormControl>
      {byDocument()}
    </>
  );
};
