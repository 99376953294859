import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { forwardStep, GlobalState, setKYC } from "app/slices/globalSlice";
import { IOnboardingDraft } from "interfaces";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const InstitutionTypes = () => {
  const { t } = useTranslation();

  const { onboarding } = useSelector<any, GlobalState>((state) => state.global);

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<IOnboardingDraft>({
    mode: "all",
    defaultValues: {
      company_category: onboarding?.company_category,
      regulated: onboarding?.regulated,
      company_name: "",
    },
  });

  const company_category = watch("company_category");

  const regulated = watch("regulated");

  useEffect(() => {
    setValue("company_category", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulated]);

  const dispatch = useDispatch();

  const doSubmit = (values: IOnboardingDraft) => {
    dispatch(
      setKYC({
        id: onboarding.id,
        ...values,
      }),
    );
    dispatch(forwardStep());
  };

  const getSub = () => {
    if (regulated === null) return null;
    return regulated ? (
      <FormControl fullWidth>
        <InputLabel id="tipo-institucion-select">
          {t("Selecciona el tipo de institución")}
        </InputLabel>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              labelId={"tipo-institucion-select"}
              label={t("Selecciona el tipo de institución")}
            >
              <MenuItem value="Instituciones de Tecnología Financiera">
                Instituciones de Tecnología Financiera (Institución de Fondos de
                Pago Electrónico - Institución de Financiamiento Colectivo)
              </MenuItem>
              <MenuItem value="Sociedades Controladoras de Grupos Financieros">
                Sociedades Controladoras de Grupos Financieros
              </MenuItem>
              <MenuItem value="Fondos de Inversión">
                Fondos de Inversión
              </MenuItem>
              <MenuItem value="Sociedades de Inversión Especializadas en Fondos para el Retiro">
                Sociedades de Inversión Especializadas en Fondos para el Retiro
              </MenuItem>
              <MenuItem value="Sociedades Operadoras de Fondos de Inversión">
                Sociedades Operadoras de Fondos de Inversión
              </MenuItem>
              <MenuItem value="Sociedades Distribuidoras de Acciones de Fondos de Inversión">
                Sociedades Distribuidoras de Acciones de Fondos de Inversión
              </MenuItem>
              <MenuItem value="Instituciones de Crédito">
                Instituciones de Crédito
              </MenuItem>
              <MenuItem value="Financiera Nacional de Desarrollo Agropecuario, Rural, Forestal y Pesquero">
                Financiera Nacional de Desarrollo Agropecuario, Rural, Forestal
                y Pesquero
              </MenuItem>
              <MenuItem value="Casas de Bolsa">Casas de Bolsa</MenuItem>
              <MenuItem value="Casas de Cambio">Casas de Cambio</MenuItem>
              <MenuItem value="Administradoras de Fondos para el Retiro">
                Administradoras de Fondos para el Retiro
              </MenuItem>
              <MenuItem value="Instituciones de Seguros">
                Instituciones de Seguros
              </MenuItem>
              <MenuItem value="Sociedades Mutualistas de Seguros">
                Sociedades Mutualistas de Seguros
              </MenuItem>
              <MenuItem value="Instituciones de Fianzas">
                Instituciones de Fianzas
              </MenuItem>
              <MenuItem value="Almacenes Generales de Depósito">
                Almacenes Generales de Depósito
              </MenuItem>
              <MenuItem value="Sociedades Cooperativas de Ahorro y Préstamo">
                Sociedades Cooperativas de Ahorro y Préstamo
              </MenuItem>
              <MenuItem value="Sociedades Financieras Populares">
                Sociedades Financieras Populares
              </MenuItem>
              <MenuItem value="Sociedades Financieras Comunitarias">
                Sociedades Financieras Comunitarias
              </MenuItem>
              <MenuItem value="Sociedades Financieras de Objeto Múltiple Regulada">
                Sociedades Financieras de Objeto Múltiple Reguladas
              </MenuItem>
              <MenuItem value="Sociedades Financieras de Objeto Múltiple No Reguladas">
                Sociedades Financieras de Objeto Múltiple No Reguladas
              </MenuItem>
              <MenuItem value="Uniones de Crédito">Uniones de Crédito</MenuItem>
              <MenuItem value="Sociedades Emisoras de Valores">
                Sociedades Emisoras de Valores
              </MenuItem>
              <MenuItem value="Entidades Financieras Extranjeras">
                Entidades Financieras Extranjeras
              </MenuItem>
              <MenuItem value="Dependencias y entidades públicas federales, estatales y municipales, así como de otras personas morales mexicanas de derecho público">
                Dependencias y entidades públicas federales, estatales y
                municipales, así como de otras personas morales mexicanas de
                derecho público
              </MenuItem>
              <MenuItem value="Bolsas de Valores">Bolsas de Valores</MenuItem>
              <MenuItem value="Instituciones para el Depósito de Valores">
                Instituciones para el Depósito de Valores
              </MenuItem>
              <MenuItem value="Sociedades que administren sistemas para facilitar operaciones con valores">
                Sociedades que administren sistemas para facilitar operaciones
                con valores
              </MenuItem>
              <MenuItem value="Contrapartes Centrales de Valores">
                Contrapartes Centrales de Valores
              </MenuItem>
              <MenuItem value="Sociedades autorizadas para operar con Modelos Novedosos conforme al Título IV de la Ley para Regular a las Instituciones de Tecnología Financiera">
                Sociedades autorizadas para operar con Modelos Novedosos
                conforme al Título IV de la Ley para Regular a las Instituciones
                de Tecnología Financiera
              </MenuItem>
            </Select>
          )}
          name={"company_category"}
          control={control}
        />
      </FormControl>
    ) : (
      <FormControl fullWidth>
        <Controller
          name={"company_category"}
          control={control}
          render={({ field }) => (
            <TextField label={t("Tipo de institución")} {...field} />
          )}
        />
      </FormControl>
    );
  };

  return (
    <form onSubmit={handleSubmit(doSubmit)} className="flex flex-col gap-5">
      <span className="text-4 pb-2 text-[#767373]">
        {t(
          "Completa los campos presentados a continuación. La revisión de la información puede tomar hasta 48 horas",
        )}
        .
      </span>
      <FormControl>
        <Controller
          control={control}
          name="company_name"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              label={t("Ingresa el nombre de la compañía")}
              {...field}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="tipo_institucion-label">
          {t("Tipo de institución")}
        </InputLabel>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              labelId={"tipo_institucion-label"}
              label={t("Tipo de institución")}
            >
              <MenuItem value={true as any}>
                {t("Institución Regulada")}
              </MenuItem>
              <MenuItem value={false as any}>
                {t("Institución No Regulada")}
              </MenuItem>
            </Select>
          )}
          name={"regulated"}
          control={control}
        />
      </FormControl>

      {getSub()}
      <Button
        variant="contained"
        type="submit"
        disabled={!isValid || regulated === null || !company_category}
      >
        {t("Continuar")}
      </Button>
    </form>
  );
};

export default InstitutionTypes;
