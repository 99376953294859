import { Button, FormControl, TextField } from "@mui/material";

import { IOnboardingDraft } from "interfaces";
import { useTranslation } from "react-i18next";

import { DynamicFormUnregulated } from "./DynamicFormUnregulated";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocument,
  forwardStep,
  GlobalState,
  setKYC,
} from "app/slices/globalSlice";

import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useUpdateOnboardingUnRegulatedMutation } from "api/institutional/onboarding";
import { useUploader } from "hooks/useUpload";

import { enqueueSnackbar } from "notistack";
import { errorSnack } from "components/Shared";
import { useEffect, useRef } from "react";
import { IDTypes } from "interfaces/enums";
import { ParticipantId } from "./ParticipantId";
import { useMergeFiles } from "./useMergeFiles";
import { UploadOrShow } from "./UploadOrShow";
import { useTrimmer } from "utils";
import { useKeyboardShortcut } from "hooks/useKeyboardShortcut";

const LegalRep = () => {
  const { control } = useFormContext<IOnboardingDraft>();
  const { onBlur } = useTrimmer();
  const { t } = useTranslation();
  return (
    <FormControl>
      <Controller
        control={control}
        name="legal_rep_name"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField label={t("Nombre completo")} {...field} onBlur={onBlur} />
        )}
      />
    </FormControl>
  );
};

export const NonRegulated = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { onboarding, step } = useSelector<any, GlobalState>(
    (state) => state.global,
  );

  const methods = useForm<IOnboardingDraft>({
    defaultValues: {
      legal_rep_name: "",
      id_type: onboarding.id_type || IDTypes.INE,
    },
    mode: "onBlur",
  });

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(
      `/kyb/document/${onboarding.id}`,
      files,
      category,
    );
    dispatch(addDocument({ filename, category }));
  };

  const { upload, error: uploadError, isLoading: isUploading } = useUploader();

  const [mutate] = useUpdateOnboardingUnRegulatedMutation();

  const legal_rep_name = methods.watch("legal_rep_name");

  const { getFiles } = useMergeFiles();

  const handleSend = async () => {
    const { corporate_shareholders, legal_rep_name } = methods.getValues();

    mutate({
      id: onboarding.id,
      company_name: onboarding.company_name.trim(),
      email: onboarding.email.trim(),
      company_category: onboarding.company_category.trim(),
      legal_rep_name: legal_rep_name?.trim() || "",
      corporate_shareholders,
      files: getFiles(corporate_shareholders),
    });
  };

  useEffect(() => {
    if (uploadError) {
      enqueueSnackbar(t("Error uploading KYB"), errorSnack);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const actaconstComplete = !!onboarding?.acta_constitutiva?.length;

  const constanciaFull =
    !!onboarding?.constancias_situacion_fiscal_sociedad?.length;

  const idcompleted = () => {
    if (
      onboarding.id_type === IDTypes.passport &&
      onboarding?.document_id?.length
    ) {
      return true;
    }
    if (
      onboarding.id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    ) {
      return true;
    }
    return false;
  };

  useKeyboardShortcut({
    key: "Enter",
    onKeyPressed: () => {
      step1Btn.current?.click();
      step2Btn.current?.click();
      step4Btn.current?.click();
    },
  });

  const handleNext = () => {
    dispatch(setKYC({ legal_rep_name }));
    dispatch(forwardStep());
  };

  const step1Btn = useRef<HTMLButtonElement>(null);
  const step2Btn = useRef<HTMLButtonElement>(null);
  const step3Btn = useRef<HTMLButtonElement>(null);
  const step4Btn = useRef<HTMLButtonElement>(null);

  const steps = () => {
    if (step === 1) {
      return (
        <>
          <span className="font-semibold text-3">
            {t("Representante Legal")}
          </span>

          <span className="text-3 text-[#737373]">
            {t("¿Quién está actuando como Representante Legal?")}
          </span>
          <LegalRep />
          <Button
            variant="contained"
            disabled={(legal_rep_name || "").split(" ").length <= 1}
            onClick={() => dispatch(forwardStep())}
            ref={step1Btn}
          >
            {t("Continuar")}
          </Button>
        </>
      );
    }
    if (step === 2) {
      return (
        <>
          <ParticipantId />
          <Button
            variant="contained"
            disabled={!idcompleted()}
            onClick={handleNext}
            ref={step2Btn}
          >
            {t("Continuar")}
          </Button>
        </>
      );
    }

    if (step === 3) {
      return (
        <>
          <span className="font-semibold text-3">
            {t(
              "Acta Constitutiva (y documentos de movimientos de acciones, en su caso)",
            )}
          </span>

          <span className="text-3 text-[#737373]">
            {t("Agrega una copia simple como imagen o PDF")}
          </span>
          <UploadOrShow
            isUploading={isUploading}
            multiUpload={true}
            category={"acta_constitutiva"}
            doDrop={doDrop}
            files={onboarding?.acta_constitutiva}
            label={
              onboarding?.acta_constitutiva?.length
                ? t("Otros documentos")
                : t("Acta Constitutiva")
            }
          />
          <Button
            variant="contained"
            disabled={!actaconstComplete}
            onClick={() => dispatch(forwardStep())}
            ref={step3Btn}
          >
            {t("Continuar")}
          </Button>
        </>
      );
    }
    if (step === 4) {
      return (
        <>
          <span className="font-semibold text-3">
            {t(
              "Constancia de Situación Fiscal de la Sociedad (no mayor a 3 meses)",
            )}
          </span>

          <span className="text-3 text-[#737373]">
            {t("Agrega una copia simple como imagen o PDF")}
          </span>

          <UploadOrShow
            isUploading={isUploading}
            multiUpload={false}
            category={"constancias_situacion_fiscal_sociedad"}
            doDrop={doDrop}
            files={onboarding?.constancias_situacion_fiscal_sociedad}
            label={t("Constancia de Situación Fiscal")}
          />

          <Button
            variant="contained"
            disabled={!constanciaFull}
            onClick={() => dispatch(forwardStep())}
            ref={step4Btn}
          >
            {t("Continuar")}
          </Button>
        </>
      );
    }
    if (step === 5) {
      return (
        <>
          <span className="font-semibold text-3">
            {t("Datos de los accionistas (con 25% participación o más)")}
          </span>

          <DynamicFormUnregulated onboardingId={onboarding.id} />
        </>
      );
    }
  };
  return (
    <FormProvider {...methods}>
      {steps()}
      {step === 5 && (
        <Button
          variant="contained"
          disabled={
            !methods.formState.isValid ||
            !legal_rep_name ||
            !actaconstComplete ||
            isUploading ||
            !idcompleted() ||
            !constanciaFull
          }
          onClick={handleSend}
        >
          {t("Enviar")}
        </Button>
      )}
    </FormProvider>
  );
};
